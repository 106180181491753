import React, { useEffect } from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import dashboard from "../../../../assets/images/dashboard.webp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faGlobe, faIdBadge, faRightToBracket } from '@fortawesome/pro-light-svg-icons';
import { faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { useLocation, useParams } from 'react-router-dom';
import GeneralService from '../../../../services/general';
import TrackingService, { DetailsData, TrackingData } from '../../../../services/trackings/tracking';
import { Label, Link, Pivot, PivotItem, Stack } from '@fluentui/react';
import TrackingTimeline from './pivot/tracking';

type TrackingSubPageProps = {};

const TrackingSubPage: React.FC<TrackingSubPageProps> = (props) => {
  const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>();
  const [identifier, setIdentifier] = React.useState<string | undefined>();
  const [searchBy, setSearchBy] = React.useState<string | undefined>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [notFound, setNotFound] = React.useState<boolean>(false);

  const [details, setDetails] = React.useState<DetailsData | undefined>();
  const [trackings, setTrackings] = React.useState<TrackingData[]>([]);

  useEffect(() => {
    getTracking();
  }, []);

  const getTracking = async () => {
    try {
      const _phoneNumber = GeneralService.getParameterByName('_p');
      const _identifier = GeneralService.getParameterByName('_i');
      const _searchby = GeneralService.getParameterByName('_b');

      if (_phoneNumber && _identifier && _searchby) {
        setIdentifier(_identifier);
        setPhoneNumber(_phoneNumber);
        setSearchBy(_searchby);

        const fd = new FormData();
        fd.append("search_by", _searchby);
        fd.append("phone_number", _phoneNumber);
        fd.append("identifier", _identifier);

        // get tracking details
        const result = await TrackingService.get(fd);
        setDetails(result.details);
        setTrackings(result.trackings.filter((t) => t.date).sort((a, b) => ((a.date || "") > (b.date || "")) ? 1 : (((b.date || "") > (a.date || "")) ? -1 : 0)));
        setLoaded(true);
      }
    } catch {

    }
  }

  const renderHeader = () => {
    let title = 'Pesanan';
    if (searchBy === 'cn') {
      title = 'Kontainer';
    }

    return <div className={styles.headerContainer}>
      <Heading variant={'h2'}>Lacak {title} #{(identifier || "").toUpperCase()}</Heading>
    </div>;
  }

  const renderDetails = (_details: DetailsData) => {
    const sales = _details.sales ? _details.sales[0] : undefined;

    return <Stack tokens={{childrenGap: 20}}>
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack styles={{ root: { width: '50%' } }}>
          <Label>Nama Perusahaan</Label>
          <Text>{_details.company_name}</Text>
        </Stack>
        <Stack styles={{ root: { width: '50%' } }}>
          <Label>PIC</Label>
          <Text>{_details.pic_name} ({_details.pic_phonenumber})</Text>
        </Stack>
      </Stack>
      {sales ? <Stack horizontal tokens={{ childrenGap: 20 }}>
        <Stack styles={{ root: { width: '50%' } }}>
          <Label>Sales</Label>
          <Text>{sales.name} (<Link href={`tel:${sales.phone_number}`}>{sales.phone_number}</Link>)</Text>
        </Stack>
      </Stack> : null}
    </Stack>
  }

  return (
    <div className={styles.containerPage}>
      <div className={styles.content}>
        <div className={styles.subContent}>
          {renderHeader()}
          {!loaded ? <Text>Harap tunggu ...</Text> : null}
          {loaded && details ? <>
            {renderDetails(details)}
            <Pivot className={'linePivot'}>
              <PivotItem headerText='Lacak' style={{padding: 20}}>
                <TrackingTimeline trackings={trackings} />
              </PivotItem>
            </Pivot>
          </> : null}
          {/*<Heading variant={'h2'}>Lacak Pengiriman Anda</Heading>
            <div className={styles.divider}></div>*/}
        </div>
      </div>
    </div>
  );
}

export default TrackingSubPage;
