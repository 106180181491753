import React, { useEffect, useState } from 'react';
import LogoDarkRectangle from './../../assets/logos/logo-dark-rectangle.webp';
import Text from './../text/text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';
import Heading from '../text/heading';
import FooterData from '../../manifests/footer';
import AboutUs from '../../manifests/reachus';
import { IPublicContactResourceshort } from '../../props/users/contact';
import { IPublicOfficeResourceshort } from '../../props/users/office';
import { faEnvelope, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { ContactType, OfficeType } from '../../pages/home';

type FooterProps = {
    contacts: IPublicContactResourceshort[]
    offices: IPublicOfficeResourceshort[]
};

const Footer: React.FC<FooterProps> = (props) => {
    const [offices, setOffices] = useState<IPublicOfficeResourceshort[]>(props.offices)

    useEffect(() => {
        setOffices(props.offices)
        return () => { }
    }, [props.offices])

    const [contacts, setContacts] = useState<IPublicContactResourceshort[]>(props.contacts)

    useEffect(() => {
        setContacts(props.contacts)
        return () => { }
    }, [props.contacts])

    const LocationPin = (p: any) => {
        return (
            <div className={styles.pin}>
                <FontAwesomeIcon icon={faLocationDot} className={styles.icon} fontSize={24} />
            </div>
        );
    }

    return <div className={styles.container}>
        <div className={styles.topSection}>
            <div className={styles.logo}>
                <img src={LogoDarkRectangle} alt={'Jejak Group - One stop solution for your logistic needs'} />
            </div>
            <div className={styles.menu}>
                <div className={styles.header}>
                    <Heading variant='h6' renderAsLabel={true}>Menu</Heading>
                    <div className={styles.indicator}> </div>
                </div>
                <div className={styles.menuItems}>
                    <div className={styles.leftMenu}>
                        {FooterData.menus.filter((m, idx) => idx < Math.ceil(FooterData.menus.length / 2)).map((menu) => {
                            return <a className={styles.link} key={menu.key} href={menu.url}><Text>{menu.text}</Text></a>
                        })}
                    </div>
                    <div className={styles.rightMenu}>
                        {FooterData.menus.filter((m, idx) => idx >= Math.ceil(FooterData.menus.length / 2)).map((menu) => {
                            return <a target={'_blank'} className={styles.link} key={menu.key} href={menu.url}><Text>{menu.text}</Text></a>
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.about}>
                <div className={styles.header}>
                    <Heading variant='h6' renderAsLabel={true}>Jejak Group</Heading>
                    <div className={styles.indicator}> </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <FontAwesomeIcon icon={faEnvelope} style={{ minWidth: 20 }} className={styles.icon} />
                        <div>
                            <a
                                target={'_blank'}
                                href={`mailto:admin@jejakgroup.com`}
                            >
                                <Text>admin@jejakgroup.com</Text>
                            </a>
                        </div>
                    </div>
                    {offices.filter(f => f.type === 'office').map(off => (
                        <div key={off.id} className={styles.item}>
                            <FontAwesomeIcon icon={faLocationDot} style={{ minWidth: 20 }} className={styles.icon} />
                            <div>
                                <a
                                    target={'_blank'}
                                    href={`https://www.google.com/maps/?q=${off.latitude},${off.longitude}`}
                                >
                                    <Text >{off.address}</Text>
                                </a>
                            </div>
                        </div>
                    ))}
                    {contacts.filter(f => f.is_featured === 1).map(off => (
                        <div key={off.id} className={styles.item}>
                            <FontAwesomeIcon icon={faWhatsapp} style={{ minWidth: 20 }} className={styles.icon} />
                            <div>
                                <a
                                    target={'_blank'}
                                    href={`https://wa.me/${off.phone_number}?text=Halo%2C%20saya%20ingin%20bertanya%20mengenai%20pengiriman%20barang%20dengan%20Jejak%20Logistik!`}
                                >
                                    <Text>{off.phone_number}</Text>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        <div className={styles.divider}> </div>
        <Text align='center'>PT. Jejak Logistik Nusantara | PT. Jejak Maritim Nusantara | All Right Reserved &copy; 2023.</Text>
    </div>;
}

export default Footer;
