import Request from '../request';
import { IPublicContactResourceshort } from '../../props/users/contact';
import DocumentService from '../documents/document';
import GeneralService from '../general';

const ContactService = {
  retrieve: async (qs?: string): Promise<IPublicContactResourceshort[]> => {
    try {
      let results: IPublicContactResourceshort[] = await Request.get(`/api/public_contacts?${qs || ''}`);

      results = await Promise.all(results.map(async (res) => {
        if (res.profile_picture_url) {
          const image = await DocumentService.get(res.profile_picture_url);
          const base64 = await GeneralService.getImageBase64(image, res.profile_picture_url.split(".").pop() || "")
          res.profile_picture = base64;
        }

        return res;
      }));

      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (id: string): Promise<IPublicContactResourceshort> => {
    try {
      const results = await Request.get(`/api/public_contacts/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default ContactService;
