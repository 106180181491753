const GeneralService = {
    getParameterByName: (name: string) => {
        const url = window.location.href.toLowerCase();
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    getImageBase64: async (base64: string, extension: string) => {
        let results: string[] = [];

        if (['jpg', 'jpeg', 'png'].indexOf(extension || "") > -1) {
            results = [`data:image/${extension};base64,${base64}`];
        }

        return results;
    }
}

export default GeneralService;