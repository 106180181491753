import React from 'react';
import styles from './styles.module.scss';

import { TrackingData } from '../../../../../../services/trackings/tracking';
import { Label, Link, Stack, Text } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faClock } from '@fortawesome/pro-solid-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { faShip, faTrain, faTruck, faUserHelmetSafety } from '@fortawesome/pro-light-svg-icons';
import TrackingDocument from '../../document';

type TrackingTimelineProps = {
    trackings: TrackingData[];
};

const TrackingTimeline: React.FC<TrackingTimelineProps> = (props) => {
    const renderCard = (tracking: TrackingData, idx?: number) => {
        let dotIcon = faCircle as IconProp;
        let dotColor = '#aaa';
        let dotSize: SizeProp = '2xs';

        let statusText = "Dijadwalkan";
        let statusVariant = 'scheduled';

        if (tracking.status === 'completed') {
            statusVariant = 'completed';
            dotIcon = faCircleCheck as IconProp;
            dotColor = '#28a745';
            dotSize = '1x';
        } else if (tracking.status === 'inprogress') {
            statusVariant = 'inprogress';
            dotIcon = faClock as IconProp;
            dotColor = '#d68227';
            dotSize = '1x';
        }

        if (tracking.type === 'truck') {
            if (tracking.status === 'inprogress') {
                statusText = 'Dalam perjalanan'
            } else if (tracking.status === 'completed') {
                statusText = 'Selesai'
            }
        } else if (tracking.type === 'load' || tracking.type === 'unload') {
            if (tracking.status === 'inprogress') {
                statusText = 'Dalam proses'
            } else if (tracking.status === 'completed') {
                statusText = 'Selesai'
            }
        } else if (tracking.type === 'ship') {
            if (tracking.status === 'inprogress') {
                statusText = 'Dalam proses'
            } else if (tracking.status === 'completed') {
                statusText = 'Selesai'
            }
        } else if (tracking.type === 'ship') {
            if (tracking.status === 'inprogress') {
                statusText = 'Berlayar'
            } else if (tracking.status === 'completed') {
                statusText = 'Sampai tujuan'
            }
        } else if (tracking.type === 'train') {
            if (tracking.status === 'inprogress') {
                statusText = 'Dalam perjalanan'
            } else if (tracking.status === 'completed') {
                statusText = 'Sampai tujuan'
            }
        }

        return <Stack horizontal className={styles.card} tokens={{ childrenGap: 20 }}>
            <Stack className={styles.status} horizontal horizontalAlign={'end'}>
                <Stack className={`${styles.tag} ${styles[statusVariant]}`}><Label style={{ fontSize: 12 }}>{statusText}</Label></Stack>
            </Stack>
            {(idx || 0) + 1 < props.trackings.length ? <Stack className={styles.line}></Stack> : null}
            <Stack className={styles.dot} horizontalAlign={'center'} verticalAlign={'center'}>
                <FontAwesomeIcon color={dotColor} icon={dotIcon} size={dotSize} />
            </Stack>
            <Stack className={styles.content} verticalAlign={'center'} tokens={{ childrenGap: 10 }}>
                <Stack className={styles.date}>
                    {tracking.type !== 'train' && tracking.type !== 'ship' ? <Label style={{ padding: 0 }}>{moment(tracking.date).format("DD/MM/YYYY HH:mm")}</Label> : null}
                    {tracking.type === 'train' || tracking.type === 'ship' ? <Label style={{ padding: 0 }}>{moment(tracking.date).format("DD/MM/YYYY")}</Label> : null}
                </Stack>
                <Stack className={styles.body} tokens={{ childrenGap: 12 }}>
                    <Label style={{ padding: 0 }}>{tracking.text}</Label>
                    {tracking.ship ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                            <FontAwesomeIcon icon={faShip} size={'sm'} style={{ width: 15 }} />
                            <Text variant={'small'}>{tracking.ship.name.toUpperCase()} - Voy #{tracking.ship.voy}</Text>
                        </Stack>
                    </> : null}
                    {tracking.train ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                            <FontAwesomeIcon icon={faTrain} size={'sm'} style={{ width: 15 }} />
                            <Text variant={'small'}>{tracking.train.name.toUpperCase()} - Voy #{tracking.train.voy}</Text>
                        </Stack>
                    </> : null}
                    {tracking.truck && (tracking.truck.driver_name || tracking.truck.driver_phonenumber || tracking.truck.registration_number) ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {tracking.truck.registration_number ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                                <FontAwesomeIcon icon={faTruck} size={'sm'} style={{ width: 15 }} />
                                <Text variant={'small'}>{tracking.truck.registration_number.toUpperCase()}</Text>
                            </Stack> : null}
                            {tracking.truck.driver_name || tracking.truck.driver_phonenumber ? <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign={'center'}>
                                <FontAwesomeIcon icon={faUserHelmetSafety} size={'sm'} style={{ width: 15 }} />
                                <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
                                    {tracking.truck.driver_name ? <Text variant={'small'}>{tracking.truck.driver_name.toUpperCase()}</Text> : null}
                                    {tracking.truck.driver_phonenumber ? <Text variant={'small'}>(<Link href={"tel:" + tracking.truck.driver_phonenumber}>{tracking.truck.driver_phonenumber.toUpperCase()}</Link>)</Text> : null}
                                </Stack>
                            </Stack> : null}
                        </Stack>
                    </> : null}
                    {(tracking.notes || "").trim() !== "" ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack><Text variant={'small'} style={{ fontStyle: 'italic' }}>Catatan: {tracking.notes}</Text></Stack>
                    </> : null}
                    {(tracking.documents || []).length > 0 ? <>
                        <Stack className={'divider'}></Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {tracking.documents.map((path) => {
                                return <TrackingDocument path={path} />
                            })}
                        </Stack>
                    </> : null}
                </Stack>
            </Stack>
        </Stack>
    }

    return <Stack>
        <Stack className={styles.timeline} tokens={{ childrenGap: 40 }}>
            {props.trackings.map(renderCard)}
        </Stack>
    </Stack>
}

export default TrackingTimeline;
