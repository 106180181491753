import Request from '../request';

export type TrackingData = {
  id: string;
  date?: string;
  documents: string[];
  type: string;
  ship?: {name: string; voy: string;};
  train?: {name: string; voy: string;};
  truck?: {registration_number?: string; driver_name?: string; driver_phonenumber?: string;};
  address: string;
  status: string;
  text: string;
  notes?: string;
}

export type DetailsData = {
  company_name: string;
  pic_name: string;
  pic_phonenumber: string;
  created_at: string;
  sales?: SalesDetailsData[];
  containers?: ContainerDetailsData[];
}

export type SalesDetailsData = {
    name: string;
    phone_number: string;
}

export type ContainerDetailsData = {
    container_number?: string;
    size?: string;
    seal_number?: string;
}

const TrackingService = {
  get: async (data: FormData): Promise<{details: DetailsData, trackings: TrackingData[]}> => {
    try {
      const results = await Request.post(`/api/public/orders/tracks`, data);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default TrackingService;
