import React, { PropsWithChildren, useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import AboutUs from '../../manifests/reachus';
import Heading from '../text/heading';
import Text from '../text/text';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { IPublicContactResourceshort } from '../../props/users/contact';
import GlobalConfig from '../../config';
import { ContactType } from '../../pages/home';

type GroupedContact = {
    key?: string;
    contacts: IPublicContactResourceshort[];
}

const WhatsappContact: React.FC<{
    contacts: IPublicContactResourceshort[]
}> = (props) => {
    const [openList, setOpenList] = React.useState<boolean>(false);

    const [contacts, setContacts] = useState<IPublicContactResourceshort[]>(props.contacts)

    useEffect(() => {
        setContacts(props.contacts)
        return () => { }
    }, [props.contacts]);

    const groupContacts = () => {
        const groups: GroupedContact[] = [];

        contacts.forEach((contact) => {
            const key = contact.country?.name || contact.province?.name || contact.city?.name;
            const gidx = groups.findIndex((group) => group.key === key);
            
            if (gidx > -1) {
                groups[gidx].contacts.push(contact);
            } else {
                groups.push({ key, contacts: [contact] });
            }
        });

        return groups;
    }

    const renderGroup = (group: GroupedContact) => {
        return <div className={styles.group}>
            <Text className={styles.title}>{group.key || "Kantor Pusat"}</Text>
            <ul className={styles.lists}>
                {group.contacts.map((au) => {
                    return <li className={styles.contact}>
                        <a className={styles.link} href={`https://wa.me/${au.phone_number}?text=Halo%2C%20saya%20ingin%20bertanya%20mengenai%20pengiriman%20barang%20dengan%20Jejak%20Logistik!`} target={'_blank'}>
                            <img className={styles.profileImage} src={au.profile_picture} alt={"Kotak tim kami " + au.name + " via whatsapp"} />
                            <div className={styles.details}>
                                <Text className={styles.name}>{au.name}</Text>
                                <Text className={styles.phone}>{au.phone_number}</Text>
                            </div>
                        </a>
                    </li>
                })}
            </ul>
        </div>
    }

    return <div>
        <a className={styles.iconContainer} onClick={() => setOpenList(true)}>
            <FontAwesomeIcon icon={faWhatsapp} fontSize={40} className={styles.icon} />
        </a>
        {openList ? <div className={styles.listContainer}>
            <div className={styles.header}>
                <Heading variant={'h6'}>Hubungi Kami</Heading>
                <a className={styles.closeIcon} onClick={() => setOpenList(false)}><FontAwesomeIcon icon={faXmark} fontSize={20} /></a>
            </div>
            {contacts.length < 1 ? <Text>Harap tunggu ...</Text> : null}
            {contacts.length > 0 ? <ul className={styles.lists}>
                {groupContacts().map(renderGroup)}
                {/*contacts.map((au) => {
                    return <li className={styles.item}>
                        <a className={styles.link} href={GlobalConfig.assetsHostname + '/' + au.profile_picture_url} target={'_blank'}>
                            <img src={GlobalConfig.assetsHostname + '/' + au.profile_picture_url} className={styles.profileImage} alt={"Kotak tim kami " + au.name + " via whatsapp"} />
                            <div className={styles.details}>
                                <Heading variant={'h6'} renderAsLabel={true}>{au.name}</Heading>
                                <Text>{au.phone_number}</Text>
                            </div>
                        </a>
                    </li>
                })*/}
            </ul> : null}
        </div> : null}
    </div>
}

export default WhatsappContact;
