import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import Heading from '../../../../components/text/heading';
import Text from '../../../../components/text/text';
import OurServices from '../../../../manifests/services';
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AboutUs from '../../../../manifests/reachus';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { IPublicOfficeResourceshort } from '../../../../props/users/office';
import OfficeService from '../../../../services/public/offices';
import { faBuilding, faContainerStorage, faLocationDot, faWarehouse } from '@fortawesome/pro-light-svg-icons';
import ContactService from '../../../../services/public/contact';
import { IPublicContactResourceshort } from '../../../../props/users/contact';
import GlobalConfig from '../../../../config';



type ReachUsSectionProps = {
    contacts: IPublicContactResourceshort[]
    offices: IPublicOfficeResourceshort[]
};

const ReachUsSection: React.FC<ReachUsSectionProps> = (props) => {
    const defaultZoom = 16;
    const defaultCenter: GoogleMapReact.Coords = AboutUs.office.coords;

    const [offices, setOffices] = useState<IPublicOfficeResourceshort[]>(props.offices)

    useEffect(() => {
        setOffices(props.offices)
        return () => { }
    }, [props.offices])

    const [contacts, setContacts] = useState<IPublicContactResourceshort[]>(props.contacts)

    useEffect(() => {
        setContacts(props.contacts)
        return () => { }
    }, [props.contacts])

    const LocationPin = (p: any) => {
        return (
            <div className={styles.pin}>
                <FontAwesomeIcon icon={faLocationDot} className={styles.icon} fontSize={24} />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.leftSection}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GlobalConfig.mapKey }}
                        defaultCenter={defaultCenter}
                        defaultZoom={defaultZoom}
                        draggable={false}
                    >
                        <LocationPin {...AboutUs.office.coords} text={'hi'} />
                    </GoogleMapReact>
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.header}>
                        <Heading variant='h2'>Hubungi Kami</Heading>
                        <div className={styles.indicator}> </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.email}>
                            <FontAwesomeIcon icon={AboutUs.email.icon} fontSize={20} />
                            <a href={AboutUs.email.url}><Text variant='secondary'>{AboutUs.email.value}</Text></a>
                        </div>
                        <div className={styles.divider}> </div>
                        {/*<div className={styles.item}>
                            <FontAwesomeIcon icon={AboutUs.office.icon} fontSize={20} style={{ marginTop: 2, width: 20 }} />
                            <div className={styles.body}>
                                <Heading variant='h6'>{AboutUs.office.title}</Heading>
                                <a target={'_blank'} href={AboutUs.office.url}><Text variant='secondary'>{AboutUs.office.value}</Text></a>
                            </div>
                        </div>*/}
                        {offices.map((item) => {
                            const getIconType = () => {
                                if (item.type === 'warehouse') {
                                    return faWarehouse
                                }
                                if (item.type === 'containerYard') {
                                    return faContainerStorage
                                }
                                if (item.type === 'office') {
                                    return faLocationDot
                                }

                                return faLocationDot
                            }
                            return <div className={styles.item}>
                                <FontAwesomeIcon icon={getIconType()} fontSize={20} style={{ marginTop: 2, width: 20 }} />
                                <div className={styles.body}>
                                    <Heading variant='h6' renderAsLabel={true}>{item.name}</Heading>
                                    <a
                                        target={'_blank'}
                                        href={`https://www.google.com/maps/?q=${item.latitude},${item.longitude}`}
                                        id={'officeaddress_' + item.name.split(' ').join('')}
                                    >
                                        <Text variant='secondary'>{item.address}</Text>
                                    </a>
                                </div>
                            </div>
                        })}
                        <div className={styles.divider}> </div>
                        {contacts.map((item) => {
                            return <a className={'button'}
                                href={`https://wa.me/${item.phone_number}?text=Halo%2C%20saya%20ingin%20bertanya%20mengenai%20pengiriman%20barang%20dengan%20Jejak%20Logistik!`} target={'_blank'} key={item.id}>
                                <FontAwesomeIcon icon={faWhatsapp} fontSize={20} style={{ marginRight: 10, position: 'relative', top: 2 }} />
                                {item.alias.toUpperCase()} ( {item.phone_number} )
                            </a>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReachUsSection;
